<template>
	<NuxtLink
		:to="'/chat/' + user.agent"
		:class="['sender-profile', { 'sender-profile--collapse': isCollapse }]"
		:title="user.fio">
		<div :class="user.attention ? 'sender-profile__new-dot' : 'sender-profile__new-dot--none'"></div>

		<UserAvatar
			:fullName="user.fio"
			:key="user.agent"
			:user-id="user.agent" />

		<div
			v-if="!isCollapse"
			class="sender-profile__content">
			<p class="p3-b sender-profile__name">
				<span class="sender-profile__fio">{{ user.fio }}</span>

				<span
					v-if="user.agent_type.name"
					class="sender-profile__type"
					>({{ user.agent_type.name }})</span
				>
			</p>

			<PageChatNumberMessages
				v-if="user.meta.new_messages_count"
				class="sender-profile__count"
				:count="user.meta.new_messages_count" />

			<p
				v-if="user.meta.last_message_text"
				class="p2 sender-profile__text">
				{{ user.meta.last_message_text }}
			</p>
		</div>
	</NuxtLink>
</template>

<script setup lang="ts">
	import { type IChatItem } from "@/assets/ts/global/interfaces/chat/IChatItem";

	const {
		activeChatStore,
		activeChatStore: { setActiveChat },
	} = useStores();
	const { getActiveChat } = storeToRefs(activeChatStore);

	const props = defineProps<{
		user: IChatItem;
		isCollapse: boolean;
		index: number;
	}>();

	const { params: routeParams } = useRoute();

	function onActiveRoute(): void {
		if (routeParams.id !== props.user.agent) return;

		setActiveChat(props.user);

		useHead({
			title: getActiveChat.value?.fio,
		});
	}

	onActiveRoute();

	function runChatPrecache() {
		const precacheChatAmount = 10;
		if (!useChatCache(props.user.agent).value && props.index + 1 <= precacheChatAmount) fetchMultiData(props.user.agent);
	}

	runChatPrecache();
</script>

<style lang="scss" scoped>
	@import "sender-profile";
</style>
